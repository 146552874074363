var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('svg', {
    attrs: {
      "width": "22",
      "height": "20",
      "viewBox": "0 0 22 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M15.7011 0.101062C17.1439 0.0119714 18.5666 0.506917 19.6387 1.47701C20.6206 2.5362 21.1216 3.94184 21.0414 5.37719V14.6228C21.1316 16.0581 20.6206 17.4638 19.6487 18.523C18.5767 19.493 17.1439 19.988 15.7011 19.8989H6.34292C4.8901 19.988 3.46734 19.493 2.39526 18.523C1.41335 17.4638 0.912379 16.0581 1.00255 14.6228V5.37719C0.912379 3.94184 1.41335 2.5362 2.39526 1.47701C3.46734 0.506917 4.8901 0.0119714 6.34292 0.101062H15.7011ZM10 14.801L16.7431 8.11919C17.3543 7.50546 17.3543 6.51556 16.7431 5.91173L15.4406 4.62487C14.8194 4.01114 13.8174 4.01114 13.1962 4.62487L12.5249 5.298C12.4247 5.39698 12.4247 5.56527 12.5249 5.66426C12.5249 5.66426 14.118 7.22829 14.1481 7.26788C14.2583 7.38667 14.3284 7.54505 14.3284 7.72323C14.3284 8.07959 14.0378 8.37656 13.6671 8.37656C13.4968 8.37656 13.3365 8.30727 13.2263 8.19838L11.553 6.55516C11.4729 6.47597 11.3326 6.47597 11.2524 6.55516L6.47317 11.2769C6.14253 11.6036 5.95216 12.0392 5.94214 12.5044L5.88202 14.8505C5.88202 14.9791 5.9221 15.0979 6.01227 15.187C6.10245 15.2761 6.22268 15.3256 6.35294 15.3256H8.70751C9.18844 15.3256 9.64933 15.1375 10 14.801Z",
      "fill": "#D4D8E2"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };