import store from "@/state/store.js";
import editIcon from "./editIcon.vue";
export default {
  components: {
    editIcon
  },
  data() {
    return {
      dialogVisible: false,
      confirmModal: false,
      editDialogVisible: false,
      page: 1,
      pageSize: 20,
      total: 0,
      buttonLoading: false,
      tableLoading: false,
      searchKey: "",
      selectList: [],
      formData: {
        name: "",
        debit_account_id: "",
        charge_account_id: "",
        charge: "",
        status: ""
      },
      editFormData: {
        name: "",
        debit_account_id: "",
        charge_account_id: "",
        charge: "",
        status: ""
      },
      rules: {
        name: [{
          required: true,
          message: "Account name is required.",
          trigger: "change"
        }],
        debitAccount: [{
          required: true,
          message: "Debit account is required",
          trigger: "blur"
        }],
        chargeAccount: [{
          required: true,
          message: "Charge account is required",
          trigger: "blur"
        }],
        status: [{
          required: true,
          message: "Status is required",
          trigger: "change"
        }],
        charge: [{
          required: true,
          message: "Charge value is required",
          trigger: "change"
        }]
      }
    };
  },
  mounted() {
    this.tableLoading = true;
    store.dispatch("admin/reporting/getPaymentAggregates").then(data => {
      this.tableLoading = false;
    }).catch(err => {
      this.$notify.error({
        title: "Error",
        message: err.message
      });
    });
    store.dispatch("admin/reporting/getChartAccounts").then(data => {
      this.selectList = data;
    }).catch(err => {
      this.$notify.error({
        title: "Error",
        message: err.message
      });
    });
  },
  methods: {
    checkEnter(e) {
      e.preventDefault();
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmModal = true;
        } else {
          return false;
        }
      });
    },
    submitNewData() {
      this.buttonLoading = true;
      store.dispatch("admin/reporting/addPaymentAggregates", this.formData).then(data => {
        this.buttonLoading = false;
        this.confirmModal = false;
        this.dialogVisible = false;
        this.$message({
          type: "success",
          message: "Gateway added successfully!"
        });
        this.$refs.ruleForm.resetFields();
        this.refetchData();
      }).catch(err => {
        this.buttonLoading = false;
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    refetchData() {
      this.tableLoading = true;
      store.dispatch("admin/reporting/getPaymentAggregates").then(data => {
        this.tableLoading = false;
      });
    },
    openEditDialog(row) {
      var _row$row$debit_accoun, _row$row$charge_accou;
      this.editFormData = {
        name: row.row.account_id,
        debit_account_id: (_row$row$debit_accoun = row.row.debit_account_id) === null || _row$row$debit_accoun === void 0 ? void 0 : _row$row$debit_accoun.name,
        charge_account_id: (_row$row$charge_accou = row.row.charge_account_id) === null || _row$row$charge_accou === void 0 ? void 0 : _row$row$charge_accou.name,
        status: row.row.status,
        charge: row.row.charge
      };
      this.editDialogVisible = true;
    },
    updateDetails(e) {
      e.preventDefault();
      // validate form
      if (this.editFormData.name == "" || this.editFormData.description == "" || this.editFormData.currency == "" || this.editFormData.charge == "" || this.editFormData.status == "") {
        this.$message({
          type: "error",
          message: "Please fill all fields!"
        });
        return;
      } else {
        this.buttonLoading = true;
        const payload = {
          name: this.editFormData.name,
          debit_account_id: this.editFormData.debit_account_id,
          charge_account_id: this.editFormData.charge_account_id,
          status: this.editFormData.status,
          charge: this.editFormData.charge
        };
        store.dispatch("admin/reporting/updatePaymentAggregates", payload).then(data => {
          this.buttonLoading = false;
          this.editDialogVisible = false;
          this.$message({
            type: "success",
            message: "Account updated successfully!"
          });
          this.refetchData();
        }).catch(err => {
          this.buttonLoading = false;
          this.editDialogVisible = false;
          this.$message({
            type: "error",
            message: "Error updating account!"
          });
        });
      }
    }
  },
  computed: {
    chartAccounts() {
      return store.getters["admin/reporting/paymentAggregates"].filter(item => {
        var _item$debit_account_i, _item$charge_account_;
        return ((_item$debit_account_i = item.debit_account_id) === null || _item$debit_account_i === void 0 ? void 0 : _item$debit_account_i.name.toLowerCase().includes(this.searchKey.toLowerCase())) || ((_item$charge_account_ = item.charge_account_id) === null || _item$charge_account_ === void 0 ? void 0 : _item$charge_account_.name.toLowerCase().includes(this.searchKey.toLowerCase())) || item.charge.toLowerCase().includes(this.searchKey.toLowerCase()) || (item === null || item === void 0 ? void 0 : item.name.toLowerCase().includes(this.searchKey.toLowerCase())) || item.account_id.toLowerCase().includes(this.searchKey.toLowerCase());
      });
    },
    selectListNotExpense() {
      return this.selectList.filter(item => {
        return item.account_type.includes("Expense") == false;
      });
    },
    selectListExpense() {
      return this.selectList.filter(item => {
        return item.account_type.includes("Expense");
      });
    }
  }
};